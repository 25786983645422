import React, {Component} from 'react';
import './SurveyResponse.css';
import QuestionResponse from './QuestionResponse'

class SurveyResponse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [
        {id: 1, question: 'Any of your usual work, housework, or school activities', answer: null},
        {id: 2, question: 'Your usual hobbies, recreational or sporting activities', answer: null},
        {id: 3, question: 'Lifiting a bag of groceries to waist level', answer: null},
        {id: 4, question: 'Lifting a bag of groceries above your head', answer: null},
        {id: 5, question: 'Grooming your hair', answer: null},
        {id: 6, question: 'Pushing up on your hands (eg. from bathtub or chair)', answer: null},
        {id: 7, question: 'Preparing food (eg. peeling, cutting)', answer: null},
        {id: 8, question: 'Driving', answer: null},
        {id: 9, question: 'Vacuuming, sweeping or raking', answer: null},
        {id: 10, question: 'Dressing', answer: null},
        {id: 11, question: 'Doing up buttons', answer: null},
        {id: 12, question: 'Using tools or appliances', answer: null},
        {id: 13, question: 'Opening doors', answer: null},
        {id: 14, question: 'Cleaning', answer: null},
        {id: 15, question: 'Tying or lacing shoes', answer: null},
        {id: 16, question: 'Sleeping', answer: null},
        {id: 17, question: 'Laundering clothes (eg. washing, ironing, folding)', answer: null},
        {id: 18, question: 'Opening a jar', answer: null},
        {id: 19, question: 'Throwing a ball', answer: null},
        {id: 20, question: 'Carrying a small suitcase with your affected limb', answer: null}
      ],
      total: 0
    }
  }

  onClickHandler = (id, value) => {
    const myTotal = this.state.total;
    const questions = [...this.state.questions];

    const index = questions.map((q) => q.id).indexOf(id);

    if(index > -1) {
      const oldValue = questions[index].answer;
      questions[index].answer = value;

      if(oldValue)
        value = value - oldValue;

      this.setState({
        total: myTotal + parseInt(value),
        questions: questions
      });
    }

  }

  onClickClearHandler = (e) => {
    e.preventDefault();
    const questions = this.state.questions;

    questions.map((q) => {
      q.answer = null;
    });

    this.setState({
      total: 0,
      questions: questions
    })
  }

  render() {
    return(
      <div>
        <div className="Question">
          <div className="table">
            <div className="header">
              <div className="cell"></div>
              <div className="cell">Activities</div>
              <div className="cell">Extreme Difficulty or<br />Unable to Perform Activity</div>
              <div className="cell">Quite a Bit of Difficulty</div>
              <div className="cell">Moderate Difficulty</div>
              <div className="cell">A Little Bit of Difficulty</div>
              <div className="cell">No Difficulty</div>
            </div>
            {this.state.questions.map((q) => (
              <QuestionResponse key={q.id} id={q.id} question={q.question} onClickHandler={this.onClickHandler} answer={q.answer} />
            ))}
          </div>
        </div>
        <div><h2>{this.state.total}</h2></div>
        <div><button onClick={this.onClickClearHandler}>Clear</button></div>
      </div>
    )
  }
};

export default SurveyResponse
