import React, {Component} from 'react';
import './SurveyResponse.css';
import CellQuestion from './CellQuestion'
import CellResponse from './CellResponse'

class QuestionResponse extends Component {
  onClickHandler = (value) => {
    console.log(value);

    this.props.onClickHandler(this.props.id, value);
  }

  render() {
    return(
      <div className="row">
        <div className="cell">{this.props.id}</div>
        <CellQuestion question={this.props.question} />
        <CellResponse onClickHandler={this.onClickHandler} value="0" selectedValue={this.props.answer} />
        <CellResponse onClickHandler={this.onClickHandler} value="1" selectedValue={this.props.answer} />
        <CellResponse onClickHandler={this.onClickHandler} value="2" selectedValue={this.props.answer} />
        <CellResponse onClickHandler={this.onClickHandler} value="3" selectedValue={this.props.answer} />
        <CellResponse onClickHandler={this.onClickHandler} value="4" selectedValue={this.props.answer} />
      </div>
    )
  }
}

export default QuestionResponse;
