import React from 'react';
import './SurveyResponse.css';

function CellQuestion(props) {
  return(
    <div className="cell question">{props.question}<br /></div>
  );
}

export default CellQuestion;
