import React, {Component} from 'react';
import './SurveyResponse.css';

class CellResponse extends Component {
  onClickHandler = (e) => {
//    if(!this.props.selectedValue) {
      this.props.onClickHandler(this.props.value);
      //e.target.classList.add("cellSelected");
//    }
  }

  render() {
    let css = null;

    if(!this.props.selectedValue) {
      css = "cell value";
    }
    else {
      if(this.props.value == this.props.selectedValue) {
        css = "cell value cellSelected";
      }
      else {
        css = "cell value cellNotSelected"
      }
    }

    return(
        <div className={css} onClick={this.onClickHandler}>{this.props.value} {this.props.className}</div>
    );
  }
}

export default CellResponse;
